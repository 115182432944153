/*****************************************************************************************
 * 설명 : 어플리케이션 설정
 *****************************************************************************************/
export const version = '1.0.15'

export const baseImgUrl = (window.location.hostname === 'localhost') ? '//localhost:8089' : '//' + window.location.hostname;

// 기본 접속 주소
export const baseHost = (window.location.hostname === 'localhost') ? '//localhost:8089' : '//' + window.location.hostname;

export const baseURL = (window.location.hostname === 'localhost') ? baseHost : '//' + window.location.hostname;

// 행정정보 공동이용 서비스 주소
export const discountURL = baseURL;

export const PAGEROW = 20

export const MESSAGE_DELAY = 3000;
